/* green 49A149
/* yellow FEF451 */

@font-face {
  font-family: "FrutigerLTPro-BlackCn";
  src: local("FrutigerLTPro-BlackCn"), url("fonts/font.woff") format("woff");
}

body {
  background: #fef451;
  margin: 40px;
  color: rgb(29, 29, 27);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Oxygen, Cantarell, sans-serif;
}

#poster {
  font-family: FrutigerLTPro-BlackCn;
}

svg {
  border: 1px solid;
}
